<template>
    <layout-default>
        <section class="services bg-light" id="bewerbung">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="card border-0 shadow-sm h-100">
                            <div class="card-body p-5 border-top border-primary border-2">
                                <h2 class="lined">Dein <span class="text-primary">Traumberuf</span>!</h2>
                                <p class="text-muted mb-4">Überzeug Dich selbst.</p>
                                <div class="row">
                                    <div class="col-md-12">
                                        <ul class="list-check list-unstyled row px-3 gy-2">
                                            <li><i class="fas fa-plus"></i> Flexible Arbeitszeitmodelle</li>
                                            <li><i class="fas fa-plus"></i> Interdisziplinär und Blick über den Tellerrand
                                            </li>
                                            <li><i class="fas fa-plus"></i> Interessante und auf Dich angepasste
                                                Karrieremöglichkeiten</li>
                                            <li><i class="fas fa-plus"></i> Überdurchschnittliches Gehalt</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="img-gradient video-block img-gradient-right">
                                    <video width="100%" height="auto" muted autoplay loop controls playsinline>
                                        <source src="../assets/img/karriere/deintraumberuf_low.mp4?v=1" type="video/mp4">
                                        Your browser does not support the video tag.
                                    </video>
                                    <!-- <img class="img-fluid shadow rounded" src="../assets/img/haende.jpg" alt=""> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <apply />
                </div>
            </div>
        </section>
        <section class="pb-5">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-5">
                        <div class="img-gradient video-block img-gradient-right">
                            <img class="img-fluid shadow rounded" src="../assets/img/karriere/balancecollage.png" alt="">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="numeric-block">
                            <p class="text-muted text-uppercase mb-2">Die Mischung macht's</p>
                            <h2>Work Life Balance</h2>
                            <p class="text-muted py-3">Du bestimmst, wann und wieviel Du arbeitest. Wir bieten Dir den
                                Rahmen dafür.</p>
                            <div class="row">
                                <div class="col-md-12">
                                    <ul class="list-check list-unstyled row px-3 gy-2">
                                        <li><i class="fas fa-users"></i> Familienfreundliche, flexible Arbeitszeiten</li>
                                        <li><i class="fas fa-sack-dollar"></i> Vollzeitgehalt ab 3.000 € und mehr</li>
                                        <li><i class="fas fa-people-arrows"></i> Entspanntes Arbeiten im Team</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="pb-5 pt-5 bg-light">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="numeric-block">
                            <p class="text-muted text-uppercase mb-2">Zukunft gemeinsam denken</p>
                            <h2>Persönlichkeitsentwicklung</h2>
                            <p class="text-muted py-3">Lass uns zusammen wachsen!</p>
                            <div class="row">
                                <div class="col-md-12">
                                    <ul class="list-check list-unstyled row px-3 gy-2">
                                        <li><i class="fas fa-graduation-cap"></i> Fortbildung mit Kostenübernahme und
                                            Urlaubsanteil</li>
                                        <li><i class="fas fa-phone-laptop"></i> Moderne Räume und digitales Arbeiten</li>
                                        <li><i class="fas fa-lightbulb-on"></i> Abwechslungsreiche Arbeitsbereiche</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 ms-auto">
                        <div class="img-gradient video-block img-gradient-right">
                            <video width="100%" height="auto" muted autoplay loop controls playsinline>
                                <source src="../assets/img/karriere/personlichkeit_low.mp4?v=1" type="video/mp4">
                                Your browser does not support the video tag.
                            </video>
                            <!--<img class="img-fluid shadow rounded" src="../assets/img/karriere/entwicklung.jpg" alt="">-->
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="pb-5 pt-5 ">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-5">
                        <div class="img-gradient video-block img-gradient-right">
                            <img class="img-fluid shadow rounded" src="../assets/img/karriere/support.jpg" alt="">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="numeric-block">
                            <p class="text-muted text-uppercase mb-2">Zusammen mehr erreichen</p>
                            <h2>Service & Support</h2>
                            <p class="text-muted py-3">
                                Wir schaffen ein Arbeitsumfeld in dem wir uns gegenseitig unterstützen
                            </p>
                            <div class="row">
                                <div class="col-md-12">
                                    <ul class="list-check list-unstyled row px-3 gy-2">
                                        <li><i class="fas fa-user-headset"></i> Rezeption und Anmeldung</li>
                                        <li><i class="fas fa-comments"></i> Regelmäßiger interdisziplinärer Austausch</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </layout-default>
</template>

<script>
import LayoutDefault from '../layouts/LayoutDefault.vue';
import Apply from '../components/Apply.vue';

export default {
    name: 'Karriere',
    components: {
        LayoutDefault,
        Apply
    }
};
</script>

<style scoped>
.order-first {
    order: -1 !important;
}

.order-0 {
    order: 0 !important;
}

.order-1 {
    order: 1 !important;
}

.order-2 {
    order: 2 !important;
}

.order-3 {
    order: 3 !important;
}

.order-4 {
    order: 4 !important;
}

.order-5 {
    order: 5 !important;
}

.order-last {
    order: 6 !important;
}

.video-block {
    position: relative;
}

.video-block .video-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.video-btn {
    transition: all 0.3s;
    position: relative;
    z-index: 99;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
    border: none;
}

.video-btn-light .video-btn-icon {
    background: #f8f9fa;
}

.video-btn:hover .video-btn-icon,
.video-btn:focus .video-btn-icon {
    background: rgb(179, 201, 54);
    ;
    color: #fff;
}

.video-btn i {
    transform: translateX(2px);
}

.video-btn:hover,
.video-btn:focus {
    text-decoration: none;
    color: rgb(179, 201, 54);
}

.video-btn-icon {
    display: block;
    width: 50px;
    height: 50px;
    border: 1px solid rgb(179, 201, 54);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: 0.85rem;
    margin: 8px;
    background: #fff;
    transition: all 0.3s;
}

.video-btn-icon::before,
.video-btn-icon::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid rgba(179, 201, 54, 0.8);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-animation: ripple-1 2s infinite ease-in-out;
    animation: ripple-1 2s infinite ease-in-out;
    z-index: -1;
}

.video-btn-icon::after {
    width: 100%;
    height: 100%;
    border-color: rgba(179, 201, 54, 0, 6);
    -webkit-animation: ripple-2 2s infinite ease-in-out;
    animation: ripple-2 2s infinite ease-in-out;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
}

@-webkit-keyframes ripple-1 {
    0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }

    100% {
        transform: translate(-50%, -50%) scale(1.8);
        opacity: 0;
    }
}

@keyframes ripple-1 {
    0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }

    100% {
        transform: translate(-50%, -50%) scale(1.8);
        opacity: 0;
    }
}

@-webkit-keyframes ripple-2 {
    0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }

    100% {
        transform: translate(-50%, -50%) scale(2);
        opacity: 0;
    }
}

@keyframes ripple-2 {
    0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }

    100% {
        transform: translate(-50%, -50%) scale(2);
        opacity: 0;
    }
}

.img-gradient {
    position: relative;
    z-index: 99;
}

.icon-block {
    width: 50px;
    height: 50px;
    border-radius: 0.25rem;
    background: rgba(43, 144, 217, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(179, 201, 54);
    font-size: 0.9rem;
    flex-shrink: 0;
}

.icon-block-lg {
    width: 80px;
    height: 80px;
}

.numeric-block {
    position: relative;
}

.numeric-block-count {
    position: absolute;
    color: rgb(179, 201, 54);
    top: -1rem;
    left: 0;
    font-size: 6rem;
    font-weight: 700;
    opacity: 0.2;
    z-index: -1;
    line-height: 1;
}

.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>
